import React, { Component, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { PlayListContext } from "../Session";

import './stylePlayingList.css';

import Loader from 'react-loader-spinner';
import makeRequest from "../pages/api/config-request";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import PerfectScrollbar from "react-perfect-scrollbar";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import Dropdown from "react-multilevel-dropdown";
import { urlList } from "../pages/api/config";
import axios from "axios";
import fileDownload from "js-file-download";
import { FacebookIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";

const Wrapper = styled.div`

  background-color: #181818; 
  height:auto;
  width: 20%;
  height: 90vh;

`;
const Wrapperr = styled.div`
 display : 'none'

`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color:#181818;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

function PlayingList({ history }) {

    const alert = useAlert()
    const { t } = useTranslation();
    const ref = useRef(null)
    const playList = useContext(PlayListContext);
    const [loading, setLoading] = useState(false);
    const [menuAlbum, setMenuAlbum] = useState(null);
    const [menuOnOpen, setMenuOnOpen] = useState(null);
    const [indexOnOpen, setIndexOnOpen] = useState(0);

    const users = localStorage.getItem('userName') ? localStorage.getItem('userName') : '1'
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '1'
    const userId = localStorage.getItem('userId') ? localStorage.getItem('userId') : '1'
    const [userPlaylist, setUserPlaylist] = useState(null);



    const splice = async (indexChild) => {
        playList.setLoadPlaylist(true);

        if (playList.playList.length == 1) {
            await playList.setPlayList(null)
            await playList.setIsPlaying('')
            await playList.setIdSong(null)
            await playList.setOnPlay(false)
            await playList.setIdPlaylist(0)
            await playList.setIdSong(null)

        }
        else if ((indexChild == playList.playList.length - 1) && (indexChild > playList.currentMusicIndex)) {
            await playList.playList.splice(indexChild, 1);


        }
        else if ((indexChild == playList.playList.length - 1) && (indexChild == playList.currentMusicIndex)) {


            await playList.setCurrentMusicIndex(playList.currentMusicIndex - 1);
            await playList.playList.splice(indexChild, 1);
        }
        else if ((playList.currentMusicIndex == playList.playList.length - 1) && (indexChild < playList.currentMusicIndex)) {


            await playList.setCurrentMusicIndex(playList.currentMusicIndex - 1);
            await playList.playList.splice(indexChild, 1);
        }
        else if (indexChild < playList.currentMusicIndex) {
            await playList.setCurrentMusicIndex(playList.currentMusicIndex - 1);
            await playList.playList.splice(indexChild, 1);




        }
        else {

            await playList.playList.splice(indexChild, 1);



        }


        await playList.setLoadPlaylist(false);

    }


    const playSingleOne = async (rowChild, index) => {
        if (playList.idSong == rowChild.id && playList.onPlay == false) {
            document.getElementById('audioPlayer').play();
        }
        else if (playList.idSong == rowChild.id && playList.onPlay == true) {
            document.getElementById('audioPlayer').pause();
        }
        else {
            playList.setCurrentMusicIndex(index)
        }



    }
    const handleDownloadAll = () => {
        if (token != '1') {
            for (let index = 0; index < playList.playList.length; index++) {
                axios.get(playList.playList[index].mediaPath, {
                    responseType: 'blob',
                })
                    .then((res) => {
                        fileDownload(res.data, playList.playList[index].songName.trim() + '.mp3')
                    })
            }
            alert.success(t('download-success'));

        }
        else {
            alert.error(t('need-login'))
        }


    }
    const downloadOne = (row) => {
        if (token != '1') {

            axios.get(row.mediaPath, {
                responseType: 'blob',
            })
                .then((res) => {
                    fileDownload(res.data, row.songName.trim() + '.mp3')
                })

            alert.success(t('download-success'));

        }
        else {
            alert.error(t('need-login'))
        }


    }

    const showUserPlaylist = async () => {
        const param = {
            timestamp: new Date().getTime(),
            page: 0,
            size: 10,

        }
        const headers = {
            userId: userId,
            token: token,
            "Accept-language": playList.language,
        }
        await makeRequest("get", urlList().URL_PERSONAL.OWNER_PLAYLIST, param, headers, {
        })
            .then(({ data }) => {
                if (data.data) {
                    setUserPlaylist(data.data)


                }

            })
            .catch((err) => {
                alert.error(t('unexpected-error') + ": " + err);

            });
    }
    const addUserPlaylistAll = async (rowPlaylist) => {

        const headers = {
            userId: userId,
            token: token,
            "Accept-language": playList.language,
        }
        for (let index = 0; index < playList.playList.length; index++) {
            let param = {
                timestamp: new Date().getTime(),
                playlistId: rowPlaylist.id,
                songId: playList.playList[index].id

            }
            await makeRequest("post", urlList().URL_PERSONAL.ADD_SONG, param, headers, {
            })
                .then(({ data }) => {

                })
                .catch((err) => {
                    alert.error(t('add-to-my-playlist-fail'));
                    alert.error(t('unexpected-error') + ": " + err);

                });

        }
        alert.success(t('add-to-my-playlist-success'));



    }
    const addUserPlaylistOne = async (rowPlaylist, song) => {

        let param = {
            timestamp: new Date().getTime(),
            playlistId: rowPlaylist.id,
            songId: song.id

        }
        const headers = {
            userId: userId,
            token: token,
            "Accept-language": playList.language,
        }

        await makeRequest("post", urlList().URL_PERSONAL.ADD_SONG, param, headers, {
        })
            .then(({ data }) => {

            })
            .catch((err) => {
                alert.error(t('add-to-my-playlist-fail'));
                alert.error(t('unexpected-error') + ": " + err);

            });


        alert.success(t('add-to-my-playlist-success'));



    }


    const likeSong = async (songId, songAvatar, songName, row) => {
        if (token === '1') {
            alert.error(t('need-login'))
        }
        else {
            playList.setLoadLike(true)
            playList.setLikeStatus('like')
            playList.setSongIdLike(songId)
            playList.setTypeLike('playing')
            row.isLike = 1;

            const param = {
                timestamp: new Date().getTime(),
                userName: users,
                action: "LIKE",
                songId: songId,
                songName: songName,
                songAvatar: songAvatar
            }

            const headers = {
                userId: userId,
                token: token,
                "Accept-language": playList.language,
            }
            await makeRequest("post", urlList().URL_SONG.LIKE, param, headers, {
            })
                .then(({ data }) => {
                    if (data.data) {

                        playList.setLoadLike(true)

                        playList.setLoadLike(false)
                    }

                })
                .catch((err) => {
                    alert.error(t('unexpected-error') + ": " + err)
                });
        }
    }
    const unLikeSong = async (songId, songAvatar, songName, row) => {
        playList.setLoadLike(true)
        playList.setLikeStatus('unlike')
        playList.setSongIdLike(songId)
        playList.setTypeLike('playing')
        row.isLike = 0;
        const param = {
            timestamp: new Date().getTime(),
            userName: users,
            action: "UNLIKE",
            songId: songId,
            songName: songName,
            songAvatar: songAvatar
        }

        const headers = {
            userId: userId,
            token: token,
            "Accept-language": playList.language,
        }
        await makeRequest("post", urlList().URL_SONG.LIKE, param, headers, {
        })
            .then(({ data }) => {
                if (data.data) {

                    playList.setLoadLike(true)

                    playList.setLoadLike(false)

                }

            })
            .catch((err) => {
                alert.error(t('unexpected-error') + ": " + err)

            });

    }
    const handleRightClickLike = (event) => {
        if (menuAlbum) {
            return;
        }
        event.preventDefault();
        setMenuAlbum({
            top: event.pageY,
            left: event.pageX
        });
    };
    const deleteAll = () => {
        playList.setPlayList(null)
        playList.setIsPlaying('')
        playList.setOnPlay(false)
        playList.setCurrentMusicIndex(0)
        playList.setIdPlaylist(0)
        playList.setIdSong(null)

    }
    const onClick = (e) => {
        if (ref.current) {
            ref.current.toggle(e);
        }
    }
    return (
        <> {
            playList.playList ?

                <Wrapper>
                    <div className="playing-list-main">
                        <div className="playing-list-top">
                            <div className="playing-list-title" id="playing-list-title">
                                {t('playing-list')}
                            </div>

                            <Dropdown

                                position="left"
                                buttonClassName="drop-down-custom-btn-playinglist"
                                wrapperClassName="drop-down-custom-wrap-playinglist"
                                menuClassName="drop-down-custom-menu"
                                title={<img src={require('../assets/more-play.png')} className="playing-list-top-img" />}
                            >
                                <Dropdown.Item
                                    className="drop-down-custom-items"
                                    onClick={() => { deleteAll() }}

                                >
                                    {t('delete-all')}
                                </Dropdown.Item>
                                <Dropdown.Item
                                    className="drop-down-custom-items"
                                    onClick={() => { handleDownloadAll() }}

                                >
                                    {t('download')}
                                </Dropdown.Item>
                                <Dropdown.Item
                                    className="drop-down-custom-items"
                                    onClick={() => {
                                        showUserPlaylist()
                                    }}
                                    onMouseEnter={
                                        () => showUserPlaylist()
                                    }

                                >

                                    {t('add-to-my-playlist')}
                                    <Dropdown.Submenu position={'left-top'} className="drop-down-custom-menu-sub" >

                                        {userPlaylist && userPlaylist.length > 0 ? userPlaylist.map((rowUP, indexUP) => (
                                            <Dropdown.Item className="drop-down-custom-items"
                                                onClick={() => {
                                                    addUserPlaylistAll(rowUP)
                                                }}>
                                                {rowUP.playlistName}
                                            </Dropdown.Item>
                                        )) :
                                            <Dropdown.Item className="drop-down-custom-items-none">
                                                {t('no-data')}
                                            </Dropdown.Item>

                                        }

                                    </Dropdown.Submenu>
                                </Dropdown.Item>

                            </Dropdown>
                        </div>
                        <Scrollbar>
                            <div className="playing-list-song">
                                {

                                    playList.loadSideBar ? <Loader type="Puff"
                                        color="#00BFFF"
                                        height={100}
                                        width={100} /> :
                                        playList.playList && playList.playList.map((rowChild, indexChild) => (
                                            <> {indexChild == playList.currentMusicIndex ?
                                                <>
                                                    <div className="playing-list-song-detail">
                                                        <div className={indexChild == playList.currentMusicIndex && playList.onPlay == true ? "playing-list-song-detail-avatar onPlaying" : "playing-list-song-detail-avatar"} style={{
                                                            backgroundImage: `url(${rowChild.avatar})`
                                                        }}>
                                                            {indexChild == playList.currentMusicIndex && playList.onPlay == true ?
                                                                <img src={require('../assets/pause.png')} className="playing-list-song-detail-avatar-img-onplay" onClick={() => { document.getElementById('audioPlayer').pause() }} /> :
                                                                <img src={require('../assets/play.png')} className="playing-list-song-detail-avatar-img" onClick={() => { playSingleOne(rowChild, indexChild) }} />}
                                                        </div>
                                                        <div className="playing-list-song-detail-text">
                                                            <div className="playing-list-song-detail-song-name">
                                                                {rowChild.songName}
                                                            </div>
                                                            <div className="playing-list-song-detail-artist">
                                                                {rowChild.artists ?
                                                                    rowChild.artists.map((rowArtists, index) => (
                                                                        <div style={{ color: 'white' }}>
                                                                            {index == rowChild.artists.length - 1 ? rowArtists.aliasName : rowArtists.aliasName + ', '}
                                                                        </div>
                                                                    )) : ''}

                                                            </div>

                                                        </div>
                                                        {
                                                            rowChild.isLike == 1 ?
                                                                <img src={require('../assets/like-solid.png')} className="playing-list-song-like" onClick={() => { unLikeSong(rowChild.id, rowChild.avatar, rowChild.songName, rowChild) }} /> :
                                                                <img src={require('../assets/like.png')} className="playing-list-song-like" onClick={() => { likeSong(rowChild.id, rowChild.avatar, rowChild.songName, rowChild) }} />
                                                        }
                                                        <Dropdown

                                                            position="left"
                                                            buttonClassName="drop-down-custom-btn-more-song"
                                                            wrapperClassName="drop-down-custom-wrap-more-song"
                                                            menuClassName="drop-down-custom-menu"
                                                            ref={ref}
                                                            title={
                                                                <img src={require('../assets/more.png')} className="playing-list-song-more"
                                                                />
                                                            }
                                                        >
                                                            <Dropdown.Item
                                                                className="drop-down-custom-items"
                                                                onClick={() => { downloadOne(rowChild); onClick() }}

                                                            >
                                                                {t('download')}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                className="drop-down-custom-items"


                                                            >
                                                                {t('copy-link')}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                className="drop-down-custom-items"
                                                            >
                                                                {t('share')}
                                                                <Dropdown.Submenu className="drop-down-custom-menu-sub" >


                                                                    <Dropdown.Item className="drop-down-custom-items"

                                                                    >
                                                                        <FacebookShareButton
                                                                            url={rowChild ? rowChild.link : ''}
                                                                        >
                                                                            <FacebookIcon size={15} round /> Facebook
                                                                        </FacebookShareButton>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="drop-down-custom-items"

                                                                    >
                                                                        <WhatsappShareButton
                                                                            url={rowChild ? rowChild.link : ''}

                                                                        >
                                                                            <WhatsappIcon size={15} round /> WhatsApp
                                                                        </WhatsappShareButton>
                                                                    </Dropdown.Item>



                                                                </Dropdown.Submenu>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                className="drop-down-custom-items"
                                                                onClick={() => {
                                                                    showUserPlaylist()
                                                                }}
                                                                onMouseEnter={
                                                                    () => showUserPlaylist()
                                                                }

                                                            >

                                                                {t('add-to-my-playlist')}
                                                                <Dropdown.Submenu className="drop-down-custom-menu-sub" >

                                                                    {userPlaylist && userPlaylist.length > 0 ? userPlaylist.map((rowUP, indexUP) => (
                                                                        <Dropdown.Item className="drop-down-custom-items"
                                                                            onClick={() => {
                                                                                addUserPlaylistOne(rowUP, rowChild)
                                                                            }}>
                                                                            {rowUP.playlistName}
                                                                        </Dropdown.Item>
                                                                    )) :
                                                                        <Dropdown.Item className="drop-down-custom-items-none">
                                                                            {t('no-data')}
                                                                        </Dropdown.Item>

                                                                    }

                                                                </Dropdown.Submenu>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                className="drop-down-custom-items"
                                                                onClick={(e) => {
                                                                    splice(indexChild);
                                                                    onClick()
                                                                }}
                                                            >
                                                                {t('delete')}
                                                            </Dropdown.Item>

                                                        </Dropdown>


                                                    </div>
                                                    {playList.playList.length == 4 ?
                                                        '' :
                                                        <div className="next-to">
                                                            {t('next-to')}
                                                        </div>

                                                    }

                                                </>
                                                : indexChild == playList.playList.length - 3 ?
                                                    <>

                                                        <div className="next-to">
                                                            {t('suggest')}
                                                        </div>
                                                        <div className="playing-list-song-detail">
                                                            <div className={indexChild == playList.currentMusicIndex && playList.onPlay == true ? "playing-list-song-detail-avatar onPlaying" : "playing-list-song-detail-avatar"} style={{
                                                                backgroundImage: `url(${rowChild.avatar})`
                                                            }}>
                                                                {indexChild == playList.currentMusicIndex && playList.onPlay == true ?
                                                                    <img src={require('../assets/pause.png')} className="playing-list-song-detail-avatar-img-onplay" onClick={() => { document.getElementById('audioPlayer').pause() }} /> :
                                                                    <img src={require('../assets/play.png')} className="playing-list-song-detail-avatar-img" onClick={() => { playSingleOne(rowChild, indexChild) }} />}
                                                            </div>
                                                            <div className="playing-list-song-detail-text">
                                                                <div className="playing-list-song-detail-song-name">
                                                                    {rowChild.songName}
                                                                </div>
                                                                <div className="playing-list-song-detail-artist">
                                                                    {rowChild.artists ?
                                                                        rowChild.artists.map((rowArtists, index) => (
                                                                            <div style={{ color: 'white' }}>
                                                                                {index == rowChild.artists.length - 1 ? rowArtists.aliasName : rowArtists.aliasName + ', '}
                                                                            </div>
                                                                        )) : ''}

                                                                </div>

                                                            </div>
                                                            {
                                                                rowChild.isLike == 1 ?
                                                                    <img src={require('../assets/like-solid.png')} className="playing-list-song-like" onClick={() => { unLikeSong(rowChild.id, rowChild.avatar, rowChild.songName, rowChild) }} /> :
                                                                    <img src={require('../assets/like.png')} className="playing-list-song-like" onClick={() => { likeSong(rowChild.id, rowChild.avatar, rowChild.songName, rowChild) }} />
                                                            }
                                                            <Dropdown

                                                                position="left"
                                                                buttonClassName="drop-down-custom-btn-more-song"
                                                                wrapperClassName="drop-down-custom-wrap-more-song"
                                                                menuClassName="drop-down-custom-menu"
                                                                ref={ref}
                                                                title={
                                                                    <img src={require('../assets/more.png')} className="playing-list-song-more"
                                                                    />
                                                                }
                                                            >
                                                                <Dropdown.Item
                                                                    className="drop-down-custom-items"
                                                                    onClick={() => { downloadOne(rowChild); onClick() }}

                                                                >
                                                                    {t('download')}
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    className="drop-down-custom-items"


                                                                >
                                                                    {t('copy-link')}
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    className="drop-down-custom-items"
                                                                >
                                                                    {t('share')}
                                                                    <Dropdown.Submenu className="drop-down-custom-menu-sub" >


                                                                        <Dropdown.Item className="drop-down-custom-items"

                                                                        >
                                                                            <FacebookShareButton
                                                                                url={rowChild ? rowChild.link : ''}
                                                                            >
                                                                                <FacebookIcon size={15} round /> Facebook
                                                                            </FacebookShareButton>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item className="drop-down-custom-items"

                                                                        >
                                                                            <WhatsappShareButton
                                                                                url={rowChild ? rowChild.link : ''}

                                                                            >
                                                                                <WhatsappIcon size={15} round /> WhatsApp
                                                                            </WhatsappShareButton>
                                                                        </Dropdown.Item>



                                                                    </Dropdown.Submenu>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    className="drop-down-custom-items"
                                                                    onClick={() => {
                                                                        showUserPlaylist()
                                                                    }}
                                                                    onMouseEnter={
                                                                        () => showUserPlaylist()
                                                                    }

                                                                >

                                                                    {t('add-to-my-playlist')}
                                                                    <Dropdown.Submenu className="drop-down-custom-menu-sub" >

                                                                        {userPlaylist && userPlaylist.length > 0 ? userPlaylist.map((rowUP, indexUP) => (
                                                                            <Dropdown.Item className="drop-down-custom-items"
                                                                                onClick={() => {
                                                                                    addUserPlaylistOne(rowUP, rowChild)
                                                                                }}>
                                                                                {rowUP.playlistName}
                                                                            </Dropdown.Item>
                                                                        )) :
                                                                            <Dropdown.Item className="drop-down-custom-items-none">
                                                                                {t('no-data')}
                                                                            </Dropdown.Item>

                                                                        }

                                                                    </Dropdown.Submenu>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    className="drop-down-custom-items"
                                                                    onClick={(e) => {
                                                                        splice(indexChild);
                                                                        onClick()
                                                                    }}
                                                                >
                                                                    {t('delete')}
                                                                </Dropdown.Item>

                                                            </Dropdown>


                                                        </div>




                                                    </>


                                                    :






                                                    <div className={indexChild < playList.currentMusicIndex ? "playing-list-song-detail has-listened" : "playing-list-song-detail"} >
                                                        <div className={indexChild == playList.currentMusicIndex && playList.onPlay == true ? "playing-list-song-detail-avatar onPlaying" : "playing-list-song-detail-avatar"} style={{
                                                            backgroundImage: `url(${rowChild.avatar})`
                                                        }}>
                                                            {indexChild == playList.currentMusicIndex && playList.onPlay == true ?
                                                                <img src={require('../assets/pause.png')} className="playing-list-song-detail-avatar-img-onplay" onClick={() => { document.getElementById('audioPlayer').pause() }} /> :
                                                                <img src={require('../assets/play.png')} className="playing-list-song-detail-avatar-img" onClick={() => { playSingleOne(rowChild, indexChild) }} />}
                                                        </div>
                                                        <div className="playing-list-song-detail-text">
                                                            <div className="playing-list-song-detail-song-name">
                                                                {rowChild.songName}
                                                            </div>
                                                            <div className="playing-list-song-detail-artist">
                                                                {rowChild.artists ?
                                                                    rowChild.artists.map((rowArtists, index) => (
                                                                        <div style={{ color: 'white' }}>
                                                                            {index == rowChild.artists.length - 1 ? rowArtists.aliasName : rowArtists.aliasName + ', '}
                                                                        </div>
                                                                    )) : ''}

                                                            </div>

                                                        </div>
                                                        {
                                                            // playList.loadLike == true ? <LoadingBar></LoadingBar> :
                                                            rowChild.isLike == 1 ?
                                                                <img src={require('../assets/like-solid.png')} className="playing-list-song-like" onClick={() => { unLikeSong(rowChild.id, rowChild.avatar, rowChild.songName, rowChild) }} /> :
                                                                <img src={require('../assets/like.png')} className="playing-list-song-like" onClick={() => { likeSong(rowChild.id, rowChild.avatar, rowChild.songName, rowChild) }} />
                                                        }

                                                        <Dropdown

                                                            position="left"
                                                            buttonClassName="drop-down-custom-btn-more-song"
                                                            wrapperClassName="drop-down-custom-wrap-more-song"
                                                            menuClassName="drop-down-custom-menu"
                                                            ref={ref}
                                                            title={
                                                                <img src={require('../assets/more.png')} className="playing-list-song-more"
                                                                />
                                                            }
                                                        >
                                                            <Dropdown.Item
                                                                className="drop-down-custom-items"
                                                                onClick={() => { downloadOne(rowChild); onClick() }}

                                                            >
                                                                {t('download')}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                className="drop-down-custom-items"


                                                            >
                                                                {t('copy-link')}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                className="drop-down-custom-items"
                                                            >
                                                                {t('share')}
                                                                <Dropdown.Submenu className="drop-down-custom-menu-sub" >


                                                                    <Dropdown.Item className="drop-down-custom-items"

                                                                    >
                                                                        <FacebookShareButton
                                                                            url={rowChild ? rowChild.link : ''}
                                                                        >
                                                                            <FacebookIcon size={15} round /> Facebook
                                                                        </FacebookShareButton>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="drop-down-custom-items"

                                                                    >
                                                                        <WhatsappShareButton
                                                                            url={menuOnOpen ? menuOnOpen.link : ''}

                                                                        >
                                                                            <WhatsappIcon size={15} round /> WhatsApp
                                                                        </WhatsappShareButton>
                                                                    </Dropdown.Item>



                                                                </Dropdown.Submenu>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                className="drop-down-custom-items"
                                                                onClick={() => {
                                                                    showUserPlaylist()
                                                                }}
                                                                onMouseEnter={
                                                                    () => showUserPlaylist()
                                                                }

                                                            >

                                                                {t('add-to-my-playlist')}
                                                                <Dropdown.Submenu className="drop-down-custom-menu-sub" >

                                                                    {userPlaylist && userPlaylist.length > 0 ? userPlaylist.map((rowUP, indexUP) => (
                                                                        <Dropdown.Item className="drop-down-custom-items"
                                                                            onClick={() => {
                                                                                addUserPlaylistOne(rowUP, rowChild)
                                                                            }}>
                                                                            {rowUP.playlistName}
                                                                        </Dropdown.Item>
                                                                    )) :
                                                                        <Dropdown.Item className="drop-down-custom-items-none">
                                                                            {t('no-data')}
                                                                        </Dropdown.Item>

                                                                    }

                                                                </Dropdown.Submenu>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                className="drop-down-custom-items"
                                                                onClick={(e) => {
                                                                    splice(indexChild);
                                                                    onClick()
                                                                }}
                                                            >
                                                                {t('delete')}
                                                            </Dropdown.Item>

                                                        </Dropdown>

                                                    </div>

                                            }
                                            </>
                                        ))

                                }


                            </div>


                        </Scrollbar>



                    </div>


                </Wrapper >

                :

                <Wrapperr>


                </Wrapperr >
        }
        </>
    );
}


export default PlayingList;
