import React, { useContext, useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import makeRequest from "../pages/api/config-request";
import { urlList } from "../pages/api/config.js";
import './style.css'
import PlayListContext from "../Session/context";

import { spacing } from "@material-ui/system";
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  withWidth,
  Modal,
  Box,
  Button,
  InputAdornment,

} from "@material-ui/core";
import JSAlert from 'js-alert';

import PerfectScrollbar from "react-perfect-scrollbar";

import { isWidthUp } from "@material-ui/core/withWidth";
import { TextField, Switch } from "@mui/material";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";


const drawerWidth = 260;
const Scrollbar = styled(PerfectScrollbar)`
  background-color: #000000;
  border-right: none;
`;
const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }


`;

const Root = styled.div`
  display: flex;
  flex-direction: row;
`;


const AppContent = styled.div`
  width:80%;
  display: flex;
  flex-direction: column;
  
`;




const CssTextField = styled(TextField)({

  '& .MuiOutlinedInput-input': {

    color: "white",
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#484848',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '50rem',
    height: '100%',
    backgroundColor: '#484848',
    '&.Mui-focused fieldset': {
      borderColor: '#484848',
      border: 'unset'
    },
  },

});


function Dashboard(props) {
  const playList = useContext(PlayListContext);
  useEffect(() => {

    relateSongList()

  }, [playList.playList]);



  const alert = useAlert()
  const { t } = useTranslation();
 
  const [open, setOpen] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [loading, setLoading] = useState(false);
  const [titlePlaylist, setTittlePlaylist] = useState('');

  const users = localStorage.getItem('userName') ? localStorage.getItem('userName') : '1'
  const token = localStorage.getItem('token') ? localStorage.getItem('token') : '1'
  const userId = localStorage.getItem('userId') ? localStorage.getItem('userId') : '1'
  const { children, routes, width } = props;


  const relateSongList = async () => {
   
    let param = {
      timestamp: new Date().getTime(),
      songId: playList?.playList[playList.currentMusicIndex].id ? playList?.playList[playList.currentMusicIndex].id : playList?.playList[playList.currentMusicIndex],
      // songId: 2366158,
      page: 0,
      size: 10
    }
   
    const headers = {
      userId: userId,
      token: token,
      "Accept-language": playList.language,
      
    }
    await makeRequest("get", urlList().URL_PLAYLIST.SONG_RELATE, param, headers, {
         
    })
      .then(({ data }) => { 
      
        if (data) {
          playList.setLoadSideBar(true);
          for (let i = 0; i < data.data.length; i++) {
              playList.playList.push(data.data[i])
          }
          playList.setLoadSideBar(false);
        }
      })
      .catch((err) => {
        alert.error(t('unexpected-error') + ": " + err)
      });

  }


  const handleOpen = () => {
    setOpen(true)
  };
  const handleClose = () => setOpen(false)


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: '#373737',
    boxShadow: 24,
    // textAlign: "center",
    borderRadius: '3%',
  };

  const createUserPlaylist = async () => {
    if (titlePlaylist === '' || titlePlaylist.length > 20) {
      JSAlert.alert(t('<20'), null, JSAlert.Icons.Failed);
    }
    else {



      const param = {
        timestamp: new Date().getTime(),
        playlistName: titlePlaylist,
        isPublic: isPublic == true ? 12 : 0,
      }
      const headers = {
        userId: userId,
        token: token,
        "Accept-language": playList.language,

      }
      makeRequest("post", urlList().URL_PERSONAL.CREATE_USER_PLAYLIST, param, headers, {
      })
        .then(({ data }) => {
          if (data) {
            setLoading(true)
            setTittlePlaylist('')
            handleClose()
            alert.success(t('create-playlist-success'))
            setLoading(false)

          }

        })
        .catch((err) => {
          JSAlert.alert(t('unexpected-error') + ": " + err, null, JSAlert.Icons.Failed);


        });
    }
  }
  return (
    <>
      {((props.children.props.location.pathname == "/sign-in") ||
        (props.children.props.location.pathname == "/sign-up") ||
        (props.children.props.location.pathname == "/reset-password") ||
        (props.children.props.location.pathname == "/infor"))

        ?
        <div style={{ width: '100%', height: '100vh', backgroundColor: 'white' }}>
          {children}
        </div>
        : 
        <>
        
          {playList.playList ?

            <Root style={{ width: '80%', height: '90vh' }}>

              <CssBaseline />
              <GlobalStyle />


              <div style={{ height: '100%', width: '20%' }}>
                <Sidebar
                  functionHanldeOpen={() => handleOpen()}
                  loading={loading}
                  routes={routes}
                  contextLoadSideBar={playList.loadSideBar}
                  PaperProps={{ style: { width: drawerWidth } }}
                />
              </div>
              <AppContent functionHanldeOpen={() => handleOpen()}>
                <Header />
                <div className="main-child-dashboard">
                  <Scrollbar >
                    {children}
                  </Scrollbar>

                </div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box style={style}>
                    <div style={{ fontSize: "30px", color: 'white', marginBottom: '20px', marginTop: '20px', textAlign: 'center' }}>
                      {t('create-playlist')}
                    </div>
                    <div style={{ marginBottom: '40px', textAlign: 'center' }}>
                      <CssTextField
                        id="outlined-basic"
                        variant="outlined"
                        autoComplete='off'
                        placeholder="Playlist #1"

                        value={titlePlaylist}
                        onChange={(event) => {
                          setTittlePlaylist(event.target.value)

                        }}
                        style={{
                          width: '80%',

                        }}

                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" placeholder="Nhập tên playlist">

                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>


                    <div style={{
                      marginBottom: '20px', height: '40px',
                      width: '80%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}>
                      <div style={{ fontSize: "12px", color: 'white', float: 'left' }}>
                        {t('public')}
                      </div>
                      <div style={{ float: 'right' }} >

                        <Switch
                          checked={isPublic}
                          onChange={() => {
                            setIsPublic(!isPublic)
                          }}
                          defaultChecked color="warning" />
                      </div>
                    </div>

                    <div style={{ marginBottom: '40px', textAlign: 'center', width: '100%' }}>
                      <Button
                        variant="contained"
                        style={{ borderRadius: '50rem', backgroundColor: 'orange', color: 'white', marginRight: 'auto', marginLeft: 'auto', width: '80%' }}
                        onClick={() => {
                          createUserPlaylist()
                        }}
                      >
                        {t('confirm')}
                      </Button>

                    </div>
                  </Box>
                </Modal>

              </AppContent>


            </Root>

            :

            <Root style={{ width: '100%', height: '100vh' }}>

              <CssBaseline />
              <GlobalStyle />


              <div style={{ height: '100%', width: '20%' }}>
                <Sidebar
                  functionHanldeOpen={() => handleOpen()}
                  loading={loading}
                  routes={routes}
                  contextLoadSideBar={playList.loadSideBar}
                  PaperProps={{ style: { width: drawerWidth } }}
                />
              </div>

              <AppContent>
                <Header />
                <div className="main-child-dashboard-none">
                  
                  <Scrollbar>
                    {children}
                  </Scrollbar>

                </div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box style={style}>
                    <div style={{ fontSize: "30px", color: 'white', marginBottom: '20px', marginTop: '20px', textAlign: 'center' }}>
                      {t('create-playlist')}
                    </div>
                    <div style={{ marginBottom: '40px', textAlign: 'center' }}>
                      <CssTextField
                        id="outlined-basic"
                        variant="outlined"
                        value={titlePlaylist}
                        onChange={(event) => {
                          setTittlePlaylist(event.target.value)

                        }}
                        style={{
                          width: '80%',

                        }}

                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" placeholder="Nhập tên playlist">

                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>


                    <div style={{
                      marginBottom: '20px', height: '40px',
                      width: '80%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}>
                      <div style={{ fontSize: "12px", color: 'white', float: 'left' }}>
                        {t('public')}
                      </div>
                      <div style={{ float: 'right' }} >

                        <Switch
                          checked={isPublic}
                          onChange={() => {
                            setIsPublic(!isPublic)
                          }}
                          defaultChecked color="warning" />
                      </div>
                    </div>

                    <div style={{ marginBottom: '40px', textAlign: 'center', width: '100%' }}>
                      <Button
                        variant="contained"
                        style={{ borderRadius: '50rem', backgroundColor: 'orange', color: 'white', marginRight: 'auto', marginLeft: 'auto', width: '80%' }}
                        onClick={() => {
                          createUserPlaylist()
                        }}
                      >
                        {t('confirm')}
                      </Button>

                    </div>
                  </Box>
                </Modal>

              </AppContent>


            </Root>





          }
        </>
      }
    </>
  );
}


export default withWidth()(Dashboard);
