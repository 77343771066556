import axios from 'axios';
import { Domain } from './config';
import { PlayListContext } from "../../Session";
import { useContext } from 'react';


export default (method, url, params = {}, headers = {}, responseType) => new Promise(async (resolve, reject) => {
    method = method.toLowerCase();


    let opts = {
        method: method,
        url: url,
        headers: {
            "Client-Type": "Web",
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept": "*/*",
            "Revision": 1,
            ...headers,
        }
    };
    if (method === 'get')
        opts.params = params;
    else
        opts.params = params;
    if (headers) {
        opts.headers = Object.assign(opts.headers, headers);
    }
    if (responseType) {
        opts.responseType = responseType;

    }
    opts.validateStatus = (status) => {
        return true;
    }

    try {
        const response = await axios(opts);
        if (response.status == 200)
            resolve(response);
        else
            reject(response)
    } catch (error) {
        reject(error)
    }
})